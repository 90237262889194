import { Box, Typography } from '@mui/material';

const NoMatch = () => {
    return (
        <Box sx={{ width: '100%' }}>
            <Typography align="center" variant="h1">
                404
            </Typography>
            <Typography align="center">Page not found</Typography>
        </Box>
    );
};

export default NoMatch;
