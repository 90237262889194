import { Card, CardContent, FormControlLabel, Switch, TextField, Typography } from '@mui/material';
import { EventDTO } from '../../../types/Events';
import React from 'react';
import { LoadingButton } from '@mui/lab';
import { updateEvent } from '../repositories/EventsRepository';
import EventPollingTimeSlider from './EventPollingTimeSlider';

interface EventCardProps {
    event: EventDTO;
    mappingName: string;
}

const EventCard = (props: EventCardProps) => {
    const { event, mappingName } = props;
    const [formData, setFormData] = React.useState<EventDTO>(event);
    const [isLoading, setIsLoading] = React.useState(false);

    const handleSave = () => {
        setIsLoading(true);
        updateEvent(formData).then(() => {
            setIsLoading(false);
        });
    };

    return (
        <Card sx={{ width: 550, height: 450, alignItems: 'center', p: 4 }}>
            <CardContent>
                <Typography gutterBottom variant="h3" component="div" sx={{ mb: 2 }}>
                    {mappingName}
                </Typography>
                <FormControlLabel
                    control={
                        <Switch
                            defaultChecked={formData.enabled}
                            onChange={(_, checked) =>
                                setFormData({
                                    ...formData,
                                    enabled: checked,
                                })
                            }
                        />
                    }
                    label="Enabled"
                    labelPlacement="end"
                    sx={{ ml: 0, mb: 2 }}
                />
                <TextField
                    sx={{ mb: 2 }}
                    label="Notification URL"
                    defaultValue={formData.notificationUrl}
                    onChange={(e) => setFormData({ ...formData, notificationUrl: e.target.value })}
                    size="small"
                    fullWidth
                    disabled={!formData.enabled}
                />
                <EventPollingTimeSlider formData={formData} setFormData={setFormData} />
                <LoadingButton loading={isLoading} onClick={handleSave} variant="contained" color="primary">
                    Save
                </LoadingButton>
            </CardContent>
        </Card>
    );
};

export default EventCard;
