import { Box, Card, CardActionArea, CardContent, CardHeader, Typography } from '@mui/material';
import React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { useNavigate } from 'react-router-dom';

interface CompanyItemsCardProps {
    title: string;
    IconComponent: React.ElementType<SvgIconProps>;
    to: string;
}

const CompanyItemsCard = (props: CompanyItemsCardProps) => {
    const navigate = useNavigate();
    const { title, IconComponent, to } = props;

    const titleComponent = (
        <Typography align="center" variant="h2">
            {title}
        </Typography>
    );
    return (
        <Card onClick={() => navigate(to)}>
            <CardActionArea sx={{ p: 2 }}>
                <CardHeader title={titleComponent} />
                <CardContent>
                    <Box sx={{ height: '15vh' }}>
                        <IconComponent sx={{ width: '100%', height: '100%' }} />
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default CompanyItemsCard;
