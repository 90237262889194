import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getCompanyByID } from './repositories/companyRepository';
import { CompanyDTO } from '../../types/Company';
import { Box, Grid, Skeleton, Typography } from '@mui/material';
import CompanyItemsCard from './components/CompanyItemsCard';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import TransformIcon from '@mui/icons-material/Transform';
import DownloadIcon from '@mui/icons-material/Download';

export default function Company() {
    const { companyId } = useParams() as { companyId: string };
    const [company, setCompany] = useState<CompanyDTO>({
        id: '',
        name: '',
        crm: '',
        api_url: '',
        login_url: '',
        login_redirect_url: '',
        environment: '',
    });
    const [isLoading, setIsLoading] = useState(true);
    const USERS_LINK = window.location.pathname + '/users';
    const MAPPING_LINK = window.location.pathname + '/mappings';
    const BULKS_LINK = window.location.pathname + '/bulks';

    useEffect(() => {
        if (isLoading) {
            const fetchCompany = async () => {
                const newCompany = (await getCompanyByID(companyId)) || company;
                setCompany(newCompany);
                localStorage.setItem('companyName', newCompany.name);
                localStorage.setItem('companyID', newCompany.id);
            };

            fetchCompany().then(() => setIsLoading(false));
        }
    }, [company, companyId, isLoading]);

    if (isLoading) {
        return <Skeleton variant="rectangular" width="100%" height="100%" />;
    }
    return (
        <Box sx={{ height: '100%', width: '100%' }}>
            <Grid container justifyContent="center" direction="row" spacing={10}>
                <Grid item xs={12} sx={{ mt: 4 }}>
                    <Typography align="center" variant="h3">
                        {company.name}
                    </Typography>
                </Grid>
                <Grid item xs={10}>
                    <Grid container justifyContent="center" direction="row" spacing={10}>
                        <Grid item xs={4}>
                            <CompanyItemsCard title="Mappings" IconComponent={TransformIcon} to={MAPPING_LINK} />
                        </Grid>
                        <Grid item xs={4}>
                            <CompanyItemsCard title="Users" IconComponent={PeopleAltIcon} to={USERS_LINK} />
                        </Grid>
                        <Grid item xs={4}>
                            <CompanyItemsCard title="Bulks" IconComponent={DownloadIcon} to={BULKS_LINK} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}
