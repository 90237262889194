import {
    Autocomplete,
    Box,
    Button,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    TextField,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';
import { CRMFieldSuggestionDTO, DisplayName, FieldDTO } from '../../../types/MappingDTO';
import AddIcon from '@mui/icons-material/Add';

interface fieldsMappingProps {
    fields: FieldDTO[];
    setFields: React.Dispatch<React.SetStateAction<FieldDTO[]>>;
    fieldSuggestions: CRMFieldSuggestionDTO[];
    displayNames: DisplayName[];
    setDisplayNames: React.Dispatch<React.SetStateAction<DisplayName[]>>;
    removeField: (id: string | undefined) => void;
    addNewField: () => void;
}

const FieldsMapping = (props: fieldsMappingProps) => {
    const { fields, setFields, fieldSuggestions, displayNames, setDisplayNames, removeField, addNewField } = props;
    const TYPE_OPTIONS = ['string', 'number', 'boolean'];

    const handleCRMAPINameChange = (value: string | null, field: FieldDTO, i: number) => {
        const stringValue = value || '';
        setFields((prevFields) => {
            prevFields[i].crmField.key = stringValue;
            return prevFields;
        });
        setDisplayNames((prevDisplayNames) => {
            const currentDisplayName = {
                id: field.id,
                displayName:
                    fieldSuggestions.find(({ apiName }) =>
                        [apiName, `properties.${apiName}`].includes(field.crmField.key)
                    )?.name || '-',
            };
            return [...prevDisplayNames.filter((displayName) => displayName.id !== field.id), currentDisplayName];
        });
    };

    return (
        <Box key="main-card-box-mapping" sx={{ width: '100%', height: '100%', p: 4, alignItems: 'center' }}>
            <Button key="addButton1" variant="outlined" onClick={addNewField} startIcon={<AddIcon />} sx={{ mb: 2 }}>
                Add Field
            </Button>
            {fields.map((field, i) => {
                return (
                    <Grid
                        key={'grid-container' + field.id}
                        container
                        direction="row"
                        spacing={2}
                        sx={{ display: 'flex', alignItems: 'center' }}
                    >
                        <Grid key={'grid-item-badger' + field.id} item xs={3}>
                            <TextField
                                id={'badgerField' + field.id}
                                key={'badgerField' + field.id}
                                label="Badger API Name"
                                defaultValue={field.badgerField?.key}
                                variant="outlined"
                                onChange={(e) => {
                                    setFields((prevFields) => {
                                        prevFields[i].badgerField.key = e.target.value;
                                        return prevFields;
                                    });
                                }}
                                margin="dense"
                                fullWidth
                                size="small"
                            />
                        </Grid>
                        <Grid key={'grid-item-type' + field.id} item xs={2}>
                            <TextField
                                id={'type' + field.id}
                                key={'type' + field.id}
                                label="Badger Type"
                                select
                                defaultValue={field.type}
                                variant="outlined"
                                onChange={(e) => {
                                    setFields((prevFields) => {
                                        prevFields[i].type = e.target.value;
                                        return prevFields;
                                    });
                                }}
                                margin="dense"
                                fullWidth
                                size="small"
                            >
                                {TYPE_OPTIONS.map((option) => {
                                    return (
                                        <MenuItem key={option + field.id} value={option}>
                                            {option}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                        </Grid>
                        <Grid key={'grid-item-crm1' + field.id} item xs={3}>
                            <Autocomplete
                                id={'crmField' + field.id}
                                key={'crmField' + field.id}
                                freeSolo
                                value={field.crmField?.key}
                                inputValue={field.crmField?.key}
                                onChange={(event, value) => handleCRMAPINameChange(value, field, i)}
                                onInputChange={(event, value) => handleCRMAPINameChange(value, field, i)}
                                options={fieldSuggestions.map((option) => option.apiName)}
                                renderInput={(params) => (
                                    <TextField {...params} label="CRM API Name" margin="dense" size="small" fullWidth />
                                )}
                            />
                        </Grid>
                        <Grid key={'grid-item-crm2' + field.id} item xs={2}>
                            <FormControl key={'crmDisplayFieldControl' + field.id} variant="outlined">
                                <InputLabel key={'crmDisplayFieldLabel' + field.id} htmlFor="displayName">
                                    Display Name
                                </InputLabel>
                                <OutlinedInput
                                    id={'crmDisplayField' + field.id}
                                    key={'crmDisplayField' + field.id}
                                    type="text"
                                    margin="dense"
                                    name="displayName"
                                    size="small"
                                    fullWidth
                                    label="Display Name"
                                    disabled
                                    value={
                                        displayNames.find((displayName) => displayName.id === field.id)?.displayName ||
                                        '-'
                                    }
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="copy name"
                                                onClick={() => {
                                                    navigator.clipboard
                                                        .writeText(
                                                            displayNames.find(
                                                                (displayName) => displayName.id === field.id
                                                            )?.displayName || '-'
                                                        )
                                                        .then(() => console.log('Copied to clipboard'));
                                                    enqueueSnackbar('Copied successfully', {
                                                        variant: 'success',
                                                    });
                                                }}
                                                edge="end"
                                            >
                                                <ContentCopyIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid key={'grid-item-delete-' + field.id} item xs={1}>
                            <Button
                                key={'remove-button' + field.id}
                                variant="outlined"
                                color="error"
                                onClick={() => removeField(field.id)}
                                startIcon={<DeleteIcon />}
                                sx={{ height: '100%' }}
                            >
                                Remove
                            </Button>
                        </Grid>
                    </Grid>
                );
            })}
            <Button key="addButton2" variant="outlined" onClick={addNewField} startIcon={<AddIcon />} sx={{ mt: 2 }}>
                Add Field
            </Button>
        </Box>
    );
};

export default FieldsMapping;
