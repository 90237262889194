import { get, post } from '../../../global/connectors/BridgeAPIConnector';
import { UserDTO } from '../../../types/UserDTO';
import presentUserFromAPI from '../presenters/UserPresenter';
import { CRMUserDTO } from '../../../types/CRMUserDTO';

export async function getAllUsers(companyId: string): Promise<UserDTO[]> {
    const users = await get(`/companies/${companyId}/users`);
    if (!users) {
        return [];
    }

    const authInformation = await get(`/companies/${companyId}/connection-health/`);

    const usersWithHealth: UserDTO[] = [];
    for (let i = 0; i < users.length; i++) {
        const presentedUser = presentUserFromAPI(users[i]);
        const userHealthInfo = authInformation.find((auth: any) => auth.user_id === presentedUser.id);
        presentedUser.isHealthy = userHealthInfo ? userHealthInfo.connection_health : false;
        usersWithHealth.push(presentedUser);
    }
    return usersWithHealth;
}

export function createUser(companyId: string, data: any): Promise<any> {
    return post(`/companies/${companyId}/users`, data);
}

export async function getCRMUsers(companyId: string): Promise<CRMUserDTO[]> {
    const users = await get(`/companies/${companyId}/connection-health/`);
    const connectedUsers = users.filter((user: any) => user.connection_health === true);

    console.log('Connected users:', connectedUsers);
    if (connectedUsers.length === 0) {
        console.log('No connected users found');
        return [];
    }

    return get(`/users/${connectedUsers[0].user_id}/metadata/crm-users`);
}
