import { useEffect, useState } from 'react';
import { UserDTO } from '../../../types/UserDTO';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import { getAllMappings } from '../../mapping/repositories/mappingRepository';
import { useParams } from 'react-router-dom';
import { MappingDTO } from '../../../types/MappingDTO';
import { newBulk } from '../BulksRepository';
import { NewBulkDTO } from '../../../types/Bulk';
import { LoadingButton } from '@mui/lab';

interface NewBulkDialogProps {
    isOpen: boolean;
    onClose: () => void;
    onNewBulkClose: () => void;
    users: UserDTO[];
}

const NewBulkDialog = ({ isOpen, onClose, onNewBulkClose, users }: NewBulkDialogProps) => {
    const { companyId } = useParams() as { companyId: string };
    const [formData, setFormData] = useState<NewBulkDTO>({
        badgerObjectName: '',
    });
    const [userId, setUserId] = useState('');
    const [mappings, setMappings] = useState<MappingDTO[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isFirstLoading, setIsFirstLoading] = useState(true);

    useEffect(() => {
        if (isFirstLoading) {
            getAllMappings(companyId).then((data) => {
                setMappings(data);
                setIsFirstLoading(false);
                setIsLoading(false);
            });
        }
    }, [companyId, isFirstLoading, isLoading]);

    const handleNewBulk = () => {
        setIsLoading(true);
        newBulk(userId, formData).then(() => {
            onNewBulkClose();
        });
    };

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>New Bulk</DialogTitle>
            <DialogContent>
                <FormControl fullWidth margin="normal">
                    <InputLabel htmlFor="badger-object-name">Badger Object Name</InputLabel>
                    <Select
                        labelId="badger-object-name"
                        id="badger-object-name"
                        label="Badger Object Name"
                        value={formData.badgerObjectName}
                        onChange={(event) => setFormData({ ...formData, badgerObjectName: event.target.value })}
                    >
                        {mappings.map((mapping) => (
                            <MenuItem key={mapping.id} value={mapping.badgerName}>
                                {mapping.badgerName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel id="user-id-select-label">Select User to Request Bulk with</InputLabel>
                    <Select
                        labelId="user-id-select-label"
                        id="user-id-select"
                        value={userId}
                        label="Select User to Request Bulk with"
                        onChange={(event) => setUserId(event.target.value)}
                    >
                        {users.map((user) => (
                            <MenuItem key={user.id} value={user.id}>
                                {user.username}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField
                    fullWidth
                    margin="normal"
                    label="Where (Optional)"
                    defaultValue=""
                    onChange={(event) => setFormData({ ...formData, where: event.target.value })}
                />
                <TextField
                    fullWidth
                    margin="normal"
                    label="Limit (Optional)"
                    type="number"
                    defaultValue=""
                    onChange={(event) => setFormData({ ...formData, limit: parseInt(event.target.value) })}
                />
                <TextField
                    fullWidth
                    margin="normal"
                    label="Notification URL (Optional)"
                    defaultValue=""
                    onChange={(event) => setFormData({ ...formData, webhookUrl: event.target.value })}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <LoadingButton variant="contained" onClick={handleNewBulk} loading={isLoading}>
                    Request
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default NewBulkDialog;
