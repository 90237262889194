import { get, post } from '../../global/connectors/BridgeAPIConnector';
import { BulkAPIRepresentation, BulkDTO, BulkStatusDTO, NewBulkDTO } from '../../types/Bulk';
import { UserDTO } from '../../types/UserDTO';

export async function getAllBulks(companyId: string): Promise<BulkDTO[]> {
    const bulks: BulkAPIRepresentation[] = await get(`/companies/${companyId}/bulks`);
    const statuses = await get(`/companies/${companyId}/status`);
    return bulks.map((bulk: BulkAPIRepresentation) => {
        const index = statuses.findIndex((status: BulkStatusDTO) => status.bulk_job_id === bulk.id);
        return {
            id: bulk.id,
            requesterUserId: bulk.requester_user_id,
            notificationUrl: bulk.webhook_url,
            badgerObjectName: bulk.badger_object_name,
            crmObjectName: bulk.crm_object_name,
            status: statuses[index].state,
            errorMessage: statuses[index].error_message,
            createdAt: bulk.created_at,
            updatedAt: bulk.updated_at,
            processedRecords: statuses[index].number_records_processed,
            mappingId: bulk.mapping_id,
        };
    });
}
export async function newBulk(userId: string, bulkData: NewBulkDTO): Promise<void> {
    await post(`/users/${userId}/bulks`, {
        badger_object_name: bulkData.badgerObjectName,
        where: bulkData.where,
        limit: bulkData.limit,
        webhook_url: bulkData.webhookUrl,
    });
}

export async function getTransformedBulkUrl(bulkId: string, userId: string): Promise<string> {
    const response = await get(`/users/${userId}/bulks/${bulkId}/result`);
    return response.url;
}

export async function getRawBulkUrl(bulkId: string, userId: string): Promise<string> {
    const response = await get(`/users/${userId}/bulks/${bulkId}/result`);
    return response.raw_url;
}

export async function getBulkProviderJobIdUrl(bulkId: string, userId: string): Promise<string> {
    const response = await get(`/users/${userId}/bulks/${bulkId}/result`);
    return response.provider_id;
}

export async function getAllUsers(companyId: string): Promise<UserDTO[]> {
    return await get(`/companies/${companyId}/users`);
}
