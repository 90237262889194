import { Box, Typography } from '@mui/material';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import TransformIcon from '@mui/icons-material/Transform';
import DownloadIcon from '@mui/icons-material/Download';

const MenuSidebar = () => {
    const urlCompanyId = window.location.pathname.split('/')[2];
    const USERS_LINK = '/companies/' + urlCompanyId + '/users';
    const MAPPINGS_LINK = '/companies/' + urlCompanyId + '/mappings';
    const BULKS_LINK = '/companies/' + urlCompanyId + '/bulks';

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Sidebar style={{ flex: '1' }}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: 30,
                        marginBottom: 30,
                    }}
                >
                    <Typography variant="h6" fontWeight={700}>
                        🌉 BRIDGE 🚀
                    </Typography>
                </div>

                <Menu
                    menuItemStyles={{
                        button: {
                            [`&.active`]: {
                                backgroundColor: '#13395e',
                                color: '#b6c8d9',
                            },
                        },
                    }}
                >
                    <MenuItem icon={<TransformIcon />} component={<Link to={MAPPINGS_LINK} />}>
                        {' '}
                        Mappings{' '}
                    </MenuItem>
                    <MenuItem icon={<PeopleAltIcon />} component={<Link to={USERS_LINK} />}>
                        {' '}
                        Users{' '}
                    </MenuItem>
                    <MenuItem icon={<DownloadIcon />} component={<Link to={BULKS_LINK} />}>
                        {' '}
                        Bulks{' '}
                    </MenuItem>
                </Menu>
            </Sidebar>
            <Sidebar>
                <Menu>
                    <MenuItem icon={<ArrowBackIcon />} component={<Link to="/" />}>
                        {' '}
                        Back to Home{' '}
                    </MenuItem>
                </Menu>
            </Sidebar>
        </Box>
    );
};

export default MenuSidebar;
