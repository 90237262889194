import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import React from 'react';
import { createCompany } from './repositories/CompaniesRepository';

export default function NewCompanyForm({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) {
    const [formData, setFormData] = React.useState({
        name: '',
        crm: 'SALESFORCE',
        login_url: '',
        api_url: '',
        login_redirect_url: '',
        environment: 'PRODUCTION',
    });

    async function handleSubmit(event: any) {
        event.preventDefault();
        createCompany(formData).then(() => {
            onClose();
        });
    }

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>Add new company</DialogTitle>
            <form autoComplete="off" onSubmit={handleSubmit}>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Name"
                        fullWidth
                        placeholder="SFDC - Test Company - Gala"
                        required
                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        id="crm"
                        label="CRM"
                        select
                        defaultValue="SALESFORCE"
                        fullWidth
                        required
                        onChange={(e) => setFormData({ ...formData, crm: e.target.value })}
                    >
                        <MenuItem key="SALESFORCE" value={'SALESFORCE'}>
                            Salesforce
                        </MenuItem>
                        <MenuItem key="HUBSPOT" value={'HUBSPOT'}>
                            Hubspot
                        </MenuItem>
                        <MenuItem key="HUBSPOT" value={'DYNAMICS'}>
                            Dynamics
                        </MenuItem>
                        <MenuItem key="ZOHO" value={'ZOHO'}>
                            Zoho
                        </MenuItem>
                        <MenuItem key="INSIGHTLY" value={'INSIGHTLY'}>
                            Insightly
                        </MenuItem>
                        <MenuItem key="NETSUITE" value={'NETSUITE'}>
                            Netsuite
                        </MenuItem>
                    </TextField>
                    <TextField
                        margin="dense"
                        id="loging_url"
                        label="Login URL"
                        placeholder="https://mydomain.my.salesforce.com"
                        fullWidth
                        required
                        onChange={(e) => setFormData({ ...formData, login_url: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        id="api_url"
                        label="API URL"
                        placeholder="https://mydomain.my.salesforce.com"
                        fullWidth
                        required
                        onChange={(e) => setFormData({ ...formData, api_url: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        id="login_redirect_url"
                        label="Login Redirect URL"
                        placeholder="https://sidekick.badgermapping.com"
                        fullWidth
                        required
                        onChange={(e) => setFormData({ ...formData, login_redirect_url: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        id="environment"
                        label="Environment"
                        select
                        defaultValue="PRODUCTION"
                        fullWidth
                        required
                        onChange={(e) => setFormData({ ...formData, environment: e.target.value })}
                    >
                        <MenuItem key="PRODUCTION" value={'PRODUCTION'}>
                            Production
                        </MenuItem>
                        <MenuItem key="SANDBOX" value={'SANDBOX'}>
                            Sandbox
                        </MenuItem>
                    </TextField>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} variant="outlined" color="primary">
                        Cancel
                    </Button>
                    <LoadingButton type="submit" variant="contained" color="primary">
                        Save Company
                    </LoadingButton>
                </DialogActions>
            </form>
        </Dialog>
    );
}
