import { MappingDTO, ObjectDTO } from '../../../types/MappingDTO';
import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import React, { useEffect } from 'react';
import { createMapping, getCRMObjectNames } from '../repositories/mappingRepository';
import { useParams } from 'react-router-dom';

interface AddMappingDialogProps {
    open: boolean;
    onClose: () => void;
    onNewMapping: (mapping: MappingDTO) => void;
}

const AddMappingDialog = ({ open, onClose, onNewMapping }: AddMappingDialogProps) => {
    const { companyId } = useParams() as { companyId: string };
    const [isLoading, setIsLoading] = React.useState(true);
    const [formData, setFormData] = React.useState<MappingDTO>({
        badgerName: '',
        crmName: '',
        fields: [
            {
                crmField: {
                    key: 'name_1',
                },
                badgerField: {
                    key: 'api_name_1',
                },
                type: 'string',
            },
        ],
        customJS: '// CRM to Badger\nif (fromVendor) {\n\n}\n// Badger to CRM\n else {\n\n}\ndone(transformedObject)',
    });
    const badgerObjectNameSuggestions = ['customers', 'checkins'];
    const [crmObjectNameSuggestions, setCrmObjectNameSuggestions] = React.useState<ObjectDTO[]>([]);

    useEffect(() => {
        if (isLoading) {
            getCRMObjectNames(companyId).then((data) => {
                setCrmObjectNameSuggestions(data);
                setIsLoading(false);
            });
        }
    });

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsLoading(true);
        createMapping(companyId, formData).then(
            () => {
                setIsLoading(false);
                onNewMapping(formData);
            },
            () => setIsLoading(false)
        );
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Add Mapping</DialogTitle>
            <form autoComplete="off" onSubmit={handleSubmit}>
                <Box sx={{ width: '400px' }}>
                    <DialogContent>
                        <Autocomplete
                            id="badgerName"
                            freeSolo
                            options={badgerObjectNameSuggestions}
                            value={formData.badgerName}
                            inputValue={formData.badgerName}
                            onChange={(e, value) =>
                                setFormData({
                                    ...formData,
                                    badgerName: value || '',
                                })
                            }
                            onInputChange={(e, value) =>
                                setFormData({
                                    ...formData,
                                    badgerName: value || '',
                                })
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    autoFocus
                                    margin="dense"
                                    label="Badger Name"
                                    fullWidth
                                    placeholder="customers"
                                    required
                                />
                            )}
                        />
                        <Typography variant="body1">Maps to:</Typography>
                        <Autocomplete
                            id="crmName"
                            freeSolo
                            options={crmObjectNameSuggestions.map((option) => option.apiName)}
                            value={formData.crmName}
                            inputValue={formData.crmName}
                            onChange={(e, value) =>
                                setFormData({
                                    ...formData,
                                    crmName: value || '',
                                })
                            }
                            onInputChange={(e, value) =>
                                setFormData({
                                    ...formData,
                                    crmName: value || '',
                                })
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    margin="dense"
                                    label="CRM Name"
                                    placeholder="Account"
                                    fullWidth
                                    required
                                />
                            )}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} variant="outlined" color="primary">
                            Cancel
                        </Button>
                        <LoadingButton type="submit" variant="contained" color="primary" loading={isLoading}>
                            Save Mapping
                        </LoadingButton>
                    </DialogActions>
                </Box>
            </form>
        </Dialog>
    );
};

export default AddMappingDialog;
