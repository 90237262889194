import { EventDTO } from '../../../types/Events';
import { Box, Grid, Input, Slider, Typography } from '@mui/material';
import React from 'react';

function EventPollingTimeSlider(props: { formData: EventDTO; setFormData: (formData: EventDTO) => void }) {
    const { formData, setFormData } = props;
    return (
        <Box sx={{ width: '100%', mb: 1 }}>
            <Typography
                id="input-slider"
                gutterBottom
                aria-disabled={!props.formData.enabled}
                color={props.formData.enabled ? 'black' : 'gray'}
            >
                Polling time in minutes:
            </Typography>
            <Grid container spacing={2} sx={{ m: 0 }}>
                <Grid item xs>
                    <Slider
                        step={1}
                        min={0}
                        max={1440}
                        value={props.formData.pollingTimeMinutes}
                        onChange={(_, value: number | number[]) =>
                            setFormData({
                                ...formData,
                                pollingTimeMinutes: value as number,
                            })
                        }
                        aria-labelledby="input-slider"
                        disabled={!props.formData.enabled}
                    />
                </Grid>
                <Grid item>
                    <Input
                        value={props.formData.pollingTimeMinutes}
                        size="small"
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                pollingTimeMinutes: e.target.value === '' ? 0 : parseInt(e.target.value),
                            })
                        }
                        inputProps={{
                            step: 1,
                            min: 0,
                            max: 1440,
                            type: 'number',
                            'aria-labelledby': 'input-slider',
                        }}
                        disabled={!props.formData.enabled}
                    />
                </Grid>
            </Grid>
            <Typography variant="caption" color="gray" sx={{ mt: 0 }}>
                If you set or see a polling time ≤5 min, it will be executed every 5 min
            </Typography>
        </Box>
    );
}

export default EventPollingTimeSlider;
