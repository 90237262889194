import { useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { EventDTO } from '../../../types/Events';
import { MappingDTO } from '../../../types/MappingDTO';
import { createEvent } from '../repositories/EventsRepository';
import EventPollingTimeSlider from './EventPollingTimeSlider';

interface AddEventDialogProps {
    open: boolean;
    onClose: () => void;
    onNewEvent: (event: EventDTO) => void;
    mappings: MappingDTO[];
}

const AddEventDialog = ({ open, onClose, onNewEvent, mappings }: AddEventDialogProps) => {
    const { userId } = useParams() as { companyId: string; userId: string };
    const [isLoading, setIsLoading] = React.useState(true);
    const [formData, setFormData] = React.useState<EventDTO>({
        mappingId: '',
        userId: userId,
        pollingTimeMinutes: 5,
        enabled: true,
        notificationUrl: '',
    });

    useEffect(() => {
        if (isLoading) {
            setIsLoading(false);
        }
    }, [isLoading]);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsLoading(true);
        createEvent(userId, formData).then(
            () => {
                setIsLoading(false);
                onNewEvent(formData);
            },
            () => setIsLoading(false)
        );
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Add Event</DialogTitle>
            <form autoComplete="off" onSubmit={handleSubmit}>
                <Box sx={{ width: '400px' }}>
                    <DialogContent>
                        <FormControl fullWidth size="small">
                            <InputLabel id="mapping-select-label">Badger Object</InputLabel>
                            <Select
                                id="mapping-select"
                                labelId="mapping-select-label"
                                sx={{ mb: 2 }}
                                onChange={(e) => setFormData({ ...formData, mappingId: e.target.value as string })}
                                size="small"
                                label="Badger Object"
                                fullWidth
                            >
                                {mappings.map((mapping) => (
                                    <MenuItem value={mapping.id}>{mapping.badgerName}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            sx={{ mb: 2 }}
                            label="Notification URL"
                            defaultValue={formData.notificationUrl}
                            onChange={(e) => setFormData({ ...formData, notificationUrl: e.target.value })}
                            size="small"
                            fullWidth
                            disabled={!formData.enabled}
                        />
                        <EventPollingTimeSlider formData={formData} setFormData={setFormData} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose}>Cancel</Button>
                        <LoadingButton loading={isLoading} type="submit" variant="contained">
                            Save
                        </LoadingButton>
                    </DialogActions>
                </Box>
            </form>
        </Dialog>
    );
};

export default AddEventDialog;
