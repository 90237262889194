import { EventAPIRepresentation, EventDTO } from '../../../types/Events';
import { get, patch, post } from '../../../global/connectors/BridgeAPIConnector';

export async function getEvents(userId: string): Promise<EventDTO[]> {
    const events = await get(`/event-configurations/?user_id=${userId}`);
    return events.map((event: EventAPIRepresentation) => {
        return {
            userId: event.user_id,
            mappingId: event.mapping_id,
            pollingTimeMinutes: event.polling_time_minutes,
            enabled: event.enabled,
            notificationUrl: event.notification_webhook_url,
            id: event.id,
        };
    });
}

export async function createEvent(userId: string, data: EventDTO): Promise<void> {
    const event = {
        user_id: userId,
        mapping_id: data.mappingId,
        polling_time_minutes: data.pollingTimeMinutes,
        enabled: data.enabled,
        notification_webhook_url: data.notificationUrl,
    };
    await post(`/event-configurations/`, event);
}

export async function updateEvent(data: EventDTO): Promise<void> {
    const event = {
        user_id: data.userId,
        mapping_id: data.mappingId,
        polling_time_minutes: data.pollingTimeMinutes,
        enabled: data.enabled,
        notification_webhook_url: data.notificationUrl,
    };
    await patch(`/event-configurations/${data.id}`, event);
}
