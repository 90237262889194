import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { CRMFieldSuggestionDTO, DisplayName, FieldDTO } from '../../types/MappingDTO';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, Skeleton, Typography } from '@mui/material';
import { getFieldSuggestions, getMappingById, updateMapping } from './repositories/IndividualMappingRepository';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { Editor } from '@monaco-editor/react';
import SaveIcon from '@mui/icons-material/Save';
import FieldsMapping from './components/FieldsMapping';
import TryOutComponent from '../try_out/TryOutComponent';
import CloseIcon from '@mui/icons-material/Close';
import { getCompanyByID } from '../company/repositories/companyRepository';
import { enqueueSnackbar } from 'notistack';

function generateGuid(): string {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}

const MappingConfig = () => {
    const { companyId, mappingId } = useParams() as { companyId: string; mappingId: string };
    const companyName = localStorage.getItem('companyName');
    const [badgerName, setBadgerName] = useState<string>('');
    const [crmName, setCrmName] = useState<string>('');
    const [fields, setFields] = useState<FieldDTO[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [customJS, setCustomJS] = useState<string>('');
    const [fieldSuggestions, setFieldSuggestions] = useState<CRMFieldSuggestionDTO[]>([]);
    const [displayNames, setDisplayNames] = useState<DisplayName[]>([]);
    const [expanded1, setExpanded1] = React.useState(false);
    const [expanded2, setExpanded2] = React.useState(false);
    const [expanded3, setExpanded3] = React.useState(false);

    const refreshMappings = React.useCallback(() => {
        getMappingById(companyId, mappingId).then((mapping) => {
            setBadgerName(mapping.badgerName);
            setCrmName(mapping.crmName);
            const fields = mapping.fields?.map((field) => {
                return {
                    id: generateGuid(),
                    crmField: { key: field.crmField.key },
                    badgerField: { key: field.badgerField.key },
                    type: field.type,
                };
            });
            setCustomJS(mapping.customJS || '');
            setFields(fields || []);
            try {
                getFieldSuggestions(companyId, mapping.crmName).then((data) => {
                    setFieldSuggestions(data);
                    fields?.forEach((field) => {
                        const currentDisplayName = {
                            id: field.id,
                            displayName:
                                data.find(({ apiName }) =>
                                    [apiName, `properties.${apiName}`].includes(field.crmField.key)
                                )?.name || '-',
                        };
                        setDisplayNames((prevDisplayNames) => {
                            return [
                                ...prevDisplayNames.filter((displayName) => displayName.id !== field.id),
                                currentDisplayName,
                            ];
                        });
                    });
                    setIsLoading(false);
                });
            } catch (e) {
                console.log(e);
                setIsLoading(false);
            }
        });
    }, [companyId, mappingId]);

    const fetchCompany = async () => {
        if (companyId !== localStorage.getItem('companyID')) {
            const newCompany = await getCompanyByID(companyId);
            localStorage.setItem('companyName', newCompany?.name || '');
            localStorage.setItem('companyID', newCompany?.id || '');
        }
    };

    useEffect(() => {
        if (isLoading) {
            fetchCompany();
            refreshMappings();
        }
    }, [isLoading, refreshMappings]);

    const companyNameComponent = (
        <Grid item xs={12} sx={{ mt: 4, mb: 1 }}>
            <Typography align="center" variant="h3">
                {companyName}
            </Typography>
        </Grid>
    );

    const titleComponent = (
        <Typography align="center" variant="h1">
            {badgerName} <SyncAltIcon /> {crmName}
        </Typography>
    );

    function removeField(id: string | undefined) {
        setFields((currentFields) => currentFields.filter((field) => field.id !== id));
    }

    function addNewField() {
        setFields((prevFields) => {
            return [
                ...prevFields,
                {
                    id: generateGuid(),
                    crmField: { key: '' },
                    badgerField: { key: '' },
                    type: 'string',
                },
            ];
        });
    }

    function saveMapping() {
        const hasEmptyFields = fields.some((field) => !field.crmField.key || !field.badgerField.key);

        if (hasEmptyFields) {
            enqueueSnackbar("Can't save with empty field names. Please fill in all fields before saving.", {
                variant: 'warning',
            });
            return;
        }

        const mapping = {
            id: mappingId,
            badgerName: badgerName,
            crmName: crmName,
            fields: fields,
            customJS: customJS,
        };
        setIsLoading(true);
        updateMapping(companyId, mapping).then(() => {
            refreshMappings();
        });
    }

    if (isLoading) {
        return <Skeleton variant="rectangular" width="100%" height="100%" />;
    }

    return (
        <Box sx={{ margin: 'auto', width: '95%' }}>
            {companyNameComponent}
            {titleComponent}
            <Button
                variant="contained"
                onClick={saveMapping}
                sx={{ marginBottom: '20px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
                startIcon={<SaveIcon />}
            >
                Save
            </Button>
            <Accordion expanded={expanded1} onChange={() => setExpanded1(!expanded1)} sx={{ marginBottom: '20px' }}>
                <AccordionSummary
                    id="panel-header"
                    aria-controls="panel-content"
                    sx={{ backgroundColor: '#f0f0f0', borderBottom: '1px solid #ddd' }}
                >
                    Mapping configuration
                </AccordionSummary>
                <AccordionDetails>
                    <FieldsMapping
                        fields={fields}
                        setFields={setFields}
                        fieldSuggestions={fieldSuggestions}
                        displayNames={displayNames}
                        setDisplayNames={setDisplayNames}
                        removeField={removeField}
                        addNewField={addNewField}
                    />
                    <Button
                        fullWidth
                        onClick={(event) => {
                            event.stopPropagation();
                            setExpanded1(false);
                        }}
                        startIcon={<CloseIcon />}
                        sx={{
                            margin: '20px 0',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: '#f0f0f0',
                        }}
                    >
                        Close
                    </Button>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded2} onChange={() => setExpanded2(!expanded2)} sx={{ marginBottom: '20px' }}>
                <AccordionSummary
                    id="panel-header"
                    aria-controls="panel-content"
                    sx={{ backgroundColor: '#f0f0f0', borderBottom: '1px solid #ddd' }}
                >
                    Custom JS
                </AccordionSummary>
                <AccordionDetails>
                    <Editor
                        height="60vh"
                        defaultLanguage="javascript"
                        value={customJS}
                        onChange={(value) => {
                            setCustomJS(value || '');
                        }}
                        options={{
                            scrollbar: {
                                vertical: 'visible',
                                horizontal: 'visible',
                            },
                        }}
                    />
                    <Button
                        fullWidth
                        onClick={(event) => {
                            event.stopPropagation();
                            setExpanded2(false);
                        }}
                        startIcon={<CloseIcon />}
                        sx={{
                            margin: '20px 0',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: '#f0f0f0',
                        }}
                    >
                        Close
                    </Button>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded3} onChange={() => setExpanded3(!expanded3)} sx={{ marginBottom: '20px' }}>
                <AccordionSummary
                    id="panel-header"
                    aria-controls="panel-content"
                    sx={{ backgroundColor: '#f0f0f0', borderBottom: '1px solid #ddd' }}
                >
                    Try out
                </AccordionSummary>
                <AccordionDetails>
                    <TryOutComponent companyId={companyId} badgerName={badgerName} crmName={crmName} />
                    <Button
                        fullWidth
                        onClick={(event) => {
                            event.stopPropagation();
                            setExpanded3(false);
                        }}
                        startIcon={<CloseIcon />}
                        sx={{
                            margin: '20px 0',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: '#f0f0f0',
                        }}
                    >
                        Close
                    </Button>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

export default MappingConfig;
