import { Card, CardActionArea, CardContent, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface MappingCardProps {
    badgerName: string;
    crmName: string;
    mappingId: string;
}

const MappingCard = (props: MappingCardProps) => {
    const { badgerName, crmName, mappingId } = props;
    const navigate = useNavigate();

    return (
        <Card sx={{ width: 350, height: 250, alignItems: 'center', p: 4 }} onClick={() => navigate(mappingId)}>
            <CardActionArea sx={{ height: '100%' }}>
                <CardContent>
                    <Typography variant="body2" color="text.secondary">
                        Badger API Name:
                    </Typography>
                    <Typography gutterBottom variant="h3" component="div">
                        {badgerName}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        CRM API Name:
                    </Typography>
                    <Typography gutterBottom variant="h4" component="div">
                        {crmName}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default MappingCard;
