import React from 'react';
import { theme } from './theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { Routes, Route, useMatch } from 'react-router-dom';
import Home from './pages/home';
import { SnackbarProvider } from 'notistack';
import { ProtectRoutes } from './hooks/protectRoutes';
import Company from './pages/company';
import MenuSidebar from './global/MenuSidebar';
import UserIndex from './pages/users';
import NoMatch from './pages/404';
import Mapping from './pages/mapping';
import MappingConfig from './pages/mapping_edit';
import EventsPage from './pages/events';
import BulksPage from './pages/bulks';

function App() {
    const match = useMatch('/companies/*');

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <SnackbarProvider>
                <div className="app">
                    {match ? <MenuSidebar /> : null}
                    <main className="content">
                        <Routes>
                            <Route element={<ProtectRoutes />}>
                                <Route path="/" element={<Home />} />
                                <Route path="/companies/:companyId" element={<Company />} />
                                <Route path="/companies/:companyId/users" element={<UserIndex />} />
                                <Route path="/companies/:companyId/users/:userId/events" element={<EventsPage />} />
                                <Route path="/companies/:companyId/mappings" element={<Mapping />} />
                                <Route path="/companies/:companyId/mappings/:mappingId" element={<MappingConfig />} />
                                <Route path="/companies/:companyId/bulks" element={<BulksPage />} />
                            </Route>
                            <Route path="*" element={<NoMatch />} />
                        </Routes>
                    </main>
                </div>
            </SnackbarProvider>
        </ThemeProvider>
    );
}

export default App;
