import { get, patch } from '../../../global/connectors/BridgeAPIConnector';
import { CompanyDTO } from '../../../types/Company';

export async function getCompanyByID(companyId: string): Promise<CompanyDTO | undefined> {
    const data = await get('/companies');
    return data.find((currentCompany: CompanyDTO) => currentCompany.id === companyId);
}

export async function updateCompany(company: CompanyDTO): Promise<any> {
    return patch(`/companies/${company.id}`, company);
}
