import { MethodMappingType } from '../types/RecordDTO';
import {
    deleteRawRecordByID,
    getRawRecordByID,
    getRawRecordsByObjectName,
    getTransformedRecordByID,
    getTransformedRecordsByObjectName,
} from '../pages/records/repositories/recordsRepository';

export const fetchData = async (
    selectedMethod: keyof MethodMappingType,
    userId: string,
    badgerName: string,
    crmName: string,
    batchSize: string,
    crmObjectID: string
) => {
    const selectedMethodFunctions = methodMapping[selectedMethod];
    let rawPayload, transformedPayload;

    switch (selectedMethod) {
        case 'GET':
            rawPayload = await selectedMethodFunctions.raw(userId, crmName, batchSize);
            transformedPayload = await selectedMethodFunctions.transformed(userId, badgerName, batchSize);
            break;
        case 'GETByID':
            rawPayload = await selectedMethodFunctions.raw(userId, crmName, crmObjectID);
            transformedPayload = await selectedMethodFunctions.transformed(userId, badgerName, crmObjectID);
            break;
        case 'DELETE':
            rawPayload = await selectedMethodFunctions.raw(userId, crmName, crmObjectID);
            transformedPayload = {};
            break;
    }

    return { rawPayload, transformedPayload };
};

const methodMapping: MethodMappingType = {
    GET: {
        raw: getRawRecordsByObjectName,
        transformed: getTransformedRecordsByObjectName,
    },
    GETByID: {
        raw: getRawRecordByID,
        transformed: getTransformedRecordByID,
    },
    DELETE: {
        raw: deleteRawRecordByID,
        transformed: deleteRawRecordByID,
    },
};
