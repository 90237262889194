import { createTheme } from '@mui/material';

// color design tokens
export const colorTokens = {
    grey: {
        '50': '#f6f6f6',
        '100': '#e7e7e7',
        '200': '#d1d1d1',
        '300': '#b0b0b0',
        '400': '#888888',
        '500': '#666666',
        '600': '#5d5d5d',
        '700': '#4f4f4f',
        '800': '#454545',
        '900': '#3d3d3d',
        '950': '#262626',
    },
    primary: {
        '50': '#f2f7fc',
        '100': '#e1ecf8',
        '200': '#c9def4',
        '300': '#a4caec',
        '400': '#79ade1',
        '500': '#598fd8',
        '600': '#4576cb',
        '700': '#3b62ba',
        '800': '#365297',
        '900': '#304678',
        '950': '#141b2d',
    },
    greenAccent: {
        '50': '#f1fcf9',
        '100': '#cff8e9',
        '200': '#9ff0d5',
        '300': '#68e0bd',
        '400': '#4cceac',
        '500': '#1fad8a',
        '600': '#168b70',
        '700': '#166f5c',
        '800': '#16594b',
        '900': '#174a40',
        '950': '#072c26',
    },
    redAccent: {
        '50': '#fdf3f3',
        '100': '#fbe6e5',
        '200': '#f9d0cf',
        '300': '#f3b0ae',
        '400': '#ea837f',
        '500': '#db4f4a',
        '600': '#c93e39',
        '700': '#a9302c',
        '800': '#8c2b28',
        '900': '#752a27',
        '950': '#3f1210',
    },
    blueAccent: {
        '50': '#edf2ff',
        '100': '#dee7ff',
        '200': '#c4d2ff',
        '300': '#a0b4ff',
        '400': '#7a8aff',
        '500': '#6870fa',
        '600': '#413cef',
        '700': '#362fd3',
        '800': '#2d29aa',
        '900': '#292986',
        '950': '#19184e',
    },
};

export const themeSettings = {
    palette: {
        primary: {
            main: colorTokens.primary[500],
        },
        secondary: {
            main: colorTokens.greenAccent[500],
        },
        neutral: {
            dark: colorTokens.grey[900],
            main: colorTokens.grey[500],
            light: colorTokens.grey[500],
        },
        background: {
            default: colorTokens.primary[50],
        },
    },
    typography: {
        fontFamily: ['Source Sans 3', 'sans-serif'].join(','),
        fontSize: 18,
        h1: {
            fontFamily: ['Source Sans 3', 'sans-serif'].join(','),
            fontSize: 40,
        },
        h2: {
            fontFamily: ['Source Sans 3', 'sans-serif'].join(','),
            fontSize: 34,
        },
        h3: {
            fontFamily: ['Source Sans 3', 'sans-serif'].join(','),
            fontSize: 28,
        },
        h4: {
            fontFamily: ['Source Sans 3', 'sans-serif'].join(','),
            fontSize: 24,
        },
        h5: {
            fontFamily: ['Source Sans 3', 'sans-serif'].join(','),
            fontSize: 22,
        },
        h6: {
            fontFamily: ['Source Sans 3', 'sans-serif'].join(','),
            fontSize: 20,
        },
    },
};

export const theme = createTheme(themeSettings);
