import { get, remove } from '../../../global/connectors/BridgeAPIConnector';

export async function getRawRecordByID(userId: string, crmObjectName: string, crmObjectID: string): Promise<string> {
    return await get(`/users/${userId}/records/raw/${crmObjectName}/${crmObjectID}`);
}

export async function getRawRecordsByObjectName(userId: string, crmObjectName: string, limit: string): Promise<string> {
    return await get(`/users/${userId}/records/raw/${crmObjectName}?limit=${limit}`);
}

export async function deleteRawRecordByID(userId: string, crmObjectName: string, crmObjectID: string): Promise<string> {
    return await remove(`/users/${userId}/records/raw/${crmObjectName}/${crmObjectID}`);
}

export async function getTransformedRecordByID(
    userId: string,
    crmObjectName: string,
    crmObjectID: string
): Promise<string> {
    return await get(`/users/${userId}/records/unified/${crmObjectName}/${crmObjectID}`);
}

export async function getTransformedRecordsByObjectName(
    userId: string,
    crmObjectName: string,
    limit: string
): Promise<string> {
    return await get(`/users/${userId}/records/unified/${crmObjectName}?limit=${limit}`);
}
