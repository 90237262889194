import { get, post } from '../../../global/connectors/BridgeAPIConnector';
import { MappingDTO, ObjectAPIRepresentation, ObjectDTO } from '../../../types/MappingDTO';

export async function getAllMappings(companyId: string): Promise<MappingDTO[]> {
    const results = await get(`/companies/${companyId}/mappings`);
    const mappings: MappingDTO[] = [];
    results.forEach((result: any) => {
        mappings.push({
            id: result.id,
            badgerName: result.badger_object_name,
            crmName: result.crm_object_name,
        });
    });
    return mappings;
}

export function createMapping(companyId: string, data: MappingDTO): Promise<any> {
    const APIFields = data.fields?.map((field) => {
        return {
            crm_field: field.crmField,
            badger_field: field.badgerField,
            type: field.type,
        };
    });
    const APIData = {
        badger_object_name: data.badgerName,
        crm_object_name: data.crmName,
        fields: APIFields,
        custom_js: data.customJS,
    };
    return post(`/companies/${companyId}/mappings`, APIData);
}

export async function getCRMObjectNames(companyId: string): Promise<ObjectDTO[]> {
    const usersWithinCompany = await get(`/companies/${companyId}/users`);
    if (usersWithinCompany.length === 0) {
        return [];
    }
    const objects: ObjectAPIRepresentation[] = await get(`/users/${usersWithinCompany[0].id}/metadata/objects`);
    if (!objects.length) {
        return [];
    }
    const objectNames: ObjectDTO[] = [];
    objects.forEach((object: ObjectAPIRepresentation) => {
        objectNames.push({
            name: object.name,
            apiName: object.api_name,
        });
    });
    return objectNames;
}
