import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

interface AddMappingCardProps {
    onClick: () => void;
}

const AddMappingCard = ({ onClick }: AddMappingCardProps) => {
    return (
        <Card sx={{ width: 350, height: 250, alignItems: 'center', p: 4 }} onClick={onClick}>
            <CardActionArea sx={{ height: '100%' }}>
                <CardContent>
                    <Typography color="primary" align="center" gutterBottom variant="h3" component="div">
                        Add new mapping
                    </Typography>
                </CardContent>
                <CardMedia sx={{ width: '100%', height: '50%', mb: 2 }}>
                    <AddCircleOutlineIcon sx={{ width: '100%', height: '100%' }} color="primary" />
                </CardMedia>
            </CardActionArea>
        </Card>
    );
};

export default AddMappingCard;
