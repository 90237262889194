import { Box, Button, IconButton, Skeleton, Tooltip } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams, GridToolbarQuickFilter } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import React, { useEffect, useState } from 'react';
import { getAllCompanies } from './repositories/CompaniesRepository';
import NewCompanyForm from './NewCompanyForm';
import { enqueueSnackbar } from 'notistack';
import { CompanyDTO } from '../../types/Company';
import EditCompanyForm from './components/EditCompanyForm';

const Home = () => {
    const [companies, setCompanies] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [isEditFormOpen, setIsEditFormOpen] = useState(false);
    const [companyToEdit, setCompanyToEdit] = useState<CompanyDTO | null>(null);

    type Row = (typeof companies)[number];

    useEffect(() => {
        if (isLoading) {
            setIsLoading(false);
            getAllCompanies().then((data) => {
                setCompanies(data);
                setIsLoading(false);
            });
        }
    }, [isLoading]);

    const onFormClose = () => {
        setIsFormOpen(false);
        refreshCompanies();
    };

    const refreshCompanies = () => {
        setIsLoading(true);
        getAllCompanies().then((data) => {
            setCompanies(data);
            setIsLoading(false);
        });
    };

    const onEditFormClose = () => {
        setIsEditFormOpen(false);
    };

    const handleEdit = (company: CompanyDTO) => {
        setCompanyToEdit(company);
        setIsEditFormOpen(true);
    };

    const copyIDToClipboard = (value: string) => {
        navigator.clipboard.writeText(value).then(() => console.log('Copied to clipboard'));
        enqueueSnackbar('Copied successfully', { variant: 'success' });
    };

    const columns: GridColDef<Row>[] = [
        { field: 'name', headerName: 'Company Name', flex: 0.7 },
        {
            field: 'id',
            headerName: 'Bridge ID',
            flex: 0.15,
            renderCell: (params: GridRenderCellParams) => (
                <strong>
                    <Tooltip title="Copy Bridge ID" arrow>
                        <IconButton onClick={() => copyIDToClipboard(params.value)}>
                            <ContentCopyIcon />
                        </IconButton>
                    </Tooltip>
                </strong>
            ),
        },
        { field: 'crm', headerName: 'CRM', flex: 0.2 },
        { field: 'environment', headerName: 'Environment', flex: 0.2 },
        {
            field: 'Open',
            headerName: '',
            flex: 0.1,
            renderCell: (params: GridRenderCellParams) => (
                <strong>
                    <Button variant="contained" size="small" href={`/companies/${params.row.id}`}>
                        Open
                    </Button>
                </strong>
            ),
        },
        {
            field: 'Edit',
            headerName: '',
            flex: 0.15,
            renderCell: (params: GridRenderCellParams) => (
                <strong>
                    <Button variant="outlined" size="small" onClick={() => handleEdit(params.row)}>
                        Edit
                    </Button>
                </strong>
            ),
        },
    ];

    return (
        <Box
            m="20px"
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <NewCompanyForm isOpen={isFormOpen} onClose={onFormClose} />
            {isEditFormOpen && companyToEdit && (
                <EditCompanyForm
                    company={companyToEdit}
                    isOpen={isEditFormOpen}
                    onClose={onEditFormClose}
                    refreshCompanies={refreshCompanies}
                />
            )}
            <Box
                sx={{
                    height: '75vh',
                    width: '75vw',
                    backgroundColor: 'white',
                }}
            >
                {isLoading ? (
                    <Skeleton width="100%" height="100%" />
                ) : (
                    <DataGrid
                        sx={{ '--DataGrid-containerBackground': 'white' }}
                        rows={companies}
                        columns={columns}
                        slots={{
                            toolbar: () => (
                                <Box
                                    sx={{
                                        p: 1,
                                        pb: 0,
                                        m: 0.5,
                                    }}
                                >
                                    <Button
                                        size="small"
                                        sx={{ m: 1 }}
                                        variant="contained"
                                        startIcon={<AddIcon />}
                                        onClick={() => setIsFormOpen(true)}
                                    >
                                        Add new company
                                    </Button>
                                    <GridToolbarQuickFilter sx={{ m: 1 }} />
                                </Box>
                            ),
                        }}
                        disableRowSelectionOnClick
                    />
                )}
            </Box>
        </Box>
    );
};

export default Home;
